// extracted by mini-css-extract-plugin
export var EntriesWrapper = "styles-module--EntriesWrapper--daXUY";
export var EntryField = "styles-module--EntryField--M5wI1";
export var EntryField_Email = "styles-module--EntryField_Email--Q2qle";
export var EntryField_Fill = "styles-module--EntryField_Fill--WYs2s";
export var EntryField_Fit = "styles-module--EntryField_Fit--gp2bu";
export var EntryField_FullName = "styles-module--EntryField_FullName--3sbIX";
export var EntryField_Menu = "styles-module--EntryField_Menu--yYXRU";
export var EntryField_Name = "styles-module--EntryField_Name--b6dzs";
export var EntryField_Permissions = "styles-module--EntryField_Permissions--eHRVr";
export var EntryField_Selector = "styles-module--EntryField_Selector--rzp6E";
export var EntryTagWrapper = "styles-module--EntryTagWrapper--Za7fA";
export var EntryWrapper = "styles-module--EntryWrapper--k4i9a";
export var FieldWrapper = "styles-module--FieldWrapper--gAuAx";
export var FullName = "styles-module--FullName--wrgTH";
export var HeaderWrapper = "styles-module--HeaderWrapper---2cep";
export var InviteEntry = "styles-module--InviteEntry--+J3gF";
export var Title = "styles-module--Title--Rt+TZ";
export var ToInvite = "styles-module--ToInvite--5FqnN";
export var ToInvite_Icon = "styles-module--ToInvite_Icon--jLfko";
export var Wrapper = "styles-module--Wrapper--Tv2K+";