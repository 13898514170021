import React from 'react'

import { useConfirmModal } from '@arch-log/webapp.shared/ConfirmModal'

import * as ProjectAuthorization from '@arch-log/webapp.modules/project.authorization.v2/components'
import * as ProjectAuthorizationRoles from '@arch-log/webapp.modules/project.authorization.v2/roles'
import * as Project from '@arch-log/webapp.modules/project.v2/components'
import * as ProjectMember from '@arch-log/webapp.modules/project.member.v2/components'
import * as ProjectMemberRoles from '@arch-log/webapp.modules/project.member/Roles'
import * as Styled from './Styled'
import * as Literals from './Literals'

/**
 */
export const Role = (props) => {
  const isRole = ProjectAuthorization.Remote.Queries.MyEntry.useIsRole()

  const [entry] = ProjectMember.Scope.Entry.useEntry()

  if (
    isRole([
      ProjectAuthorizationRoles.Owner,
      ProjectAuthorizationRoles.Admin,
    ]) &&
    entry.role !== ProjectAuthorizationRoles.Owner
  ) {
    return <RoleEditor {...props} />
  }

  return <RoleViewer {...props} />
}

const RoleEditor = ({
  components: { RoleSelector = Styled.RoleSelector } = {},
}) => {
  const [entry] = ProjectMember.Scope.Entry.useEntry()

  const { role: currentRole } = entry

  const [role, setRole] = React.useState(currentRole)

  const availableRoles = [
    ProjectMemberRoles.Reader,
    ProjectMemberRoles.Editor,
  ].map((role) => ({
    value: role,
    label: role,
  }))

  const {
    changeProjectMemberRole,
  } = ProjectMember.Remote.Mutations.ChangeProjectMemberRole.useMutation()

  const handleRoleChange = (e) => {
    setRole(e.target.value)
    changeProjectMemberRole({
      variables: { userId: entry.id, role: e.target.value },
    })
  }

  return (
    <RoleSelector
      options={availableRoles}
      value={role}
      onChange={handleRoleChange}
      renderOption={({ label, ...props }) => (
        <Literals.RoleName
          role={label}
          render={({ children: formattedLabel, render: Renderer }) => (
            <Styled.DefaultOptionRenderer {...props} label={formattedLabel} />
          )}
        />
      )}
    />
  )
}

const RoleViewer = ({ components: { Decorator = Styled.RoleTag } = {} }) => (
  <Decorator>
    <ProjectMember.Scope.Entry.Properties.RoleName
      formatter={(v) => <Literals.RoleName role={v} />}
    />
  </Decorator>
)

/**
 */
export const Menu = ({
  components: {
    Wrapper = Styled.MenuWrapper,
    Toggle = Styled.MenuToggle,
    Items = Styled.MenuItems,
    Item = Styled.MenuItem,
  } = {},
}) => {
  const [visible, setVisible] = React.useState(false)

  const ref = React.useRef()

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setVisible(false)
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  return (
    <Wrapper ref={ref}>
      <Toggle
        onClick={() => setVisible(true)}
        onBlur={() => setVisible(false)}
        isToggled={visible}
      >
        ...
      </Toggle>
      <Items isShown={visible}>
        <Item>
          <ChangeOwner />
        </Item>
        <Item>
          <RemoveEntry />
        </Item>
      </Items>
    </Wrapper>
  )
}

/**
 */
export const ChangeOwner = ({
  components: { Button = Styled.MenuButton } = {},
}) => {
  const [entry] = ProjectMember.Scope.Entry.useEntry()
  const { showConfirm } = useConfirmModal()

  const {
    refetch: refetchAuhorization,
  } = ProjectAuthorization.Remote.Queries.MyEntry.useEntry()

  const {
    refetch: refetchProjectMember,
  } = ProjectMember.Remote.Queries.Entries.useContext()

  const [
    changeProjectOwner,
  ] = Project.Remote.Mutations.ChangeProjectOwner.useMutation()

  return (
    <Button
      onClick={() => {
        showConfirm({
          message: <Literals.ChangeOwnerMessage />,
          onOk: () => {
            changeProjectOwner({
              variables: { newOwnerId: entry?.uuid },
              onCompleted: (data) => {
                if (refetchAuhorization) {
                  refetchAuhorization()
                }

                if (refetchProjectMember) {
                  refetchProjectMember()
                }
              },
            })
          },
        })
      }}
    >
      <Literals.ChangeOwner />
    </Button>
  )
}

/**
 */
export const RemoveEntry = ({
  components: { Button = Styled.MenuButton } = {},
}) => {
  const { showConfirm } = useConfirmModal()

  const [entry] = ProjectMember.Scope.Entry.useEntry()

  const { refetch } = ProjectMember.Remote.Queries.Entries.useContext()

  const {
    deleteEntry,
  } = ProjectMember.Remote.Mutations.DeleteEntry.useMutation()

  return (
    <Button
      onClick={() => {
        showConfirm({
          message: <Literals.RemoveMemberMessage />,
          onOk: () => {
            deleteEntry({
              variables: { userId: entry.id },
              onCompleted: (data) => {
                if (refetch) {
                  refetch()
                }
              },
            })
          },
        })
      }}
    >
      <Literals.RemoveMember />
    </Button>
  )
}
