import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Message = ({ children, ...props }) => (
  <div {...props} className={styles.Message}>
    {children}
  </div>
)

/**
 */
export const ErrorMessage = ({ children, ...props }) => (
  <div {...props} className={styles.ErrorMessage}>
    {children}
  </div>
)

export const ButtonContainer = ({ children }) => (
  <div className={styles.ButtonContainer}>{children}</div>
)

/**
 */
export const OkButton = ({ children, ...props }) => (
  <div {...props} className={styles.OkButton}>
    {children}
  </div>
)

/**
 */
export const CancelButton = ({ children, ...props }) => (
  <div {...props} className={styles.CancelButton}>
    {children}
  </div>
)
