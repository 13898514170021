import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const EntriesWrapper = ({ children }) => (
  <div className={styles.EntriesWrapper}>{children}</div>
)

/**
 */
export const EntriesHeader = ({ children }) => <>{children}</>

/**
 */
export const EntryWrapper = ({ children }) => (
  <div className={styles.EntryWrapper}>{children}</div>
)

/**
 */
export const EntryFieldFill = ({ children }) => (
  <div className={styles.EntryField_Fill}>{children}</div>
)

/**
 */
export const EntryFieldFit = ({ children }) => (
  <div className={styles.EntryField_Fit}>{children}</div>
)

/**
 */
export const EntryFieldName = ({ children }) => (
  <div className={styles.EntryField_Name}>{children}</div>
)

/**
 */
export const EntryFieldSelector = ({ children }) => (
  <div className={styles.EntryField_Selector}>{children}</div>
)

/**
 */
export const EntryFieldMenu = ({ children }) => (
  <div className={styles.EntryField_Menu}>{children}</div>
)

/**
 */
export const Title = ({ children }) => (
  <span className={styles.Title}> {children}</span>
)

/**
 */
export const InviteWrapper = ({ children }) => (
  <div className={styles.InviteEntry}>{children}</div>
)
