import React from 'react'

import * as Containers from './Containers'
import * as Layout from './Layout'

/**
 */
export const Default = ({
  components: {
    Layout: {
      Wrapper = Layout.Wrapper,
      Header = Layout.Header,
      Body = Layout.Body,
      Footer = Layout.Footer,
    } = {},
  } = {},
  ...props
}) => (
  <Containers.Initializer>
    <Wrapper>
      <Header>
        <Containers.Header />
      </Header>
      <Body>
        <Containers.Entries>
          <Containers.Entry />
        </Containers.Entries>
      </Body>
      <Footer>
        <Containers.Footer />
      </Footer>
    </Wrapper>
  </Containers.Initializer>
)
