import React from 'react'

import * as Literals from './Literals'
import * as Styled from './Styled'
import * as Modal from '@arch-log/webapp.atomics/components/Modal'

export const Cancel = ({
  onCancel,
  isVisible,
  components: { Button = Styled.CancelButton } = {},
  label: Label = <Literals.Cancel />,
}) => {
  const { hide } = React.useContext(Modal.Context)

  if (!isVisible) {
    return null
  }

  return (
    <Button
      onClick={() => {
        onCancel()
        hide()
      }}
    >
      {typeof Label === 'function' ? <Label /> : Label}
    </Button>
  )
}

export const Ok = ({
  onOk,
  isVisible,
  components: { Button = Styled.OkButton } = {},
  label: Label = <Literals.Ok />,
}) => {
  const { hide } = React.useContext(Modal.Context)

  if (!isVisible) {
    return null
  }
  return (
    <Button
      onClick={(e) => {
        const event = { hideModal: true }
        onOk(event)
        if (event.hideModal) {
          hide()
        }
      }}
    >
      {typeof Label === 'function' ? <Label /> : Label}
    </Button>
  )
}
