import React from 'react'

import { Page as PageLoader } from '@arch-log/webapp.atomics/components/Loader'

import * as ProjectAuthorization from '@arch-log/webapp.modules/project.authorization.v2/components'
import * as ProjectAuthorizationRoles from '@arch-log/webapp.modules/project.authorization.v2/roles'
import * as Permissions from '@arch-log/webapp.modules/project.authorization.v2/permissions'
import * as Project from '@arch-log/webapp.modules/project.v2/components'
import * as ProjectMember from '@arch-log/webapp.modules/project.member.v2/components'
import * as ProjectMemberRoles from '@arch-log/webapp.modules/project.member.v2/Roles'

import * as Literals from './Literals'
import * as BaseEntry from './Entry'
import * as Styled from './Styled'
import * as Menus from './Menus'

/**
 */
export const Initializer = ({ children }) => {
  const [projectEntry] = Project.Scope.Entry.useEntry()

  return (
    <ProjectMember.Remote.Queries.Entries.Loader
      projectUuid={projectEntry.uuid}
      components={{
        Loading: () => <PageLoader>Loading project member</PageLoader>,
      }}
    >
      <ActionInitializer>{children}</ActionInitializer>
    </ProjectMember.Remote.Queries.Entries.Loader>
  )
}

const ActionInitializer = ({ children }) => {
  const [projectEntry] = Project.Scope.Entry.useEntry()

  const { refetch } = ProjectMember.Remote.Queries.Entries.useContext()

  return (
    <ProjectMember.Remote.Mutations.ChangeProjectMemberRole.Initializer
      projectId={projectEntry.id}
      onCompleted={() => {
        refetch()
      }}
    >
      <ProjectMember.Remote.Mutations.DeleteEntry.Initializer
        projectId={projectEntry.id}
      >
        <Project.Remote.Mutations.ChangeProjectOwner.Initializer
          projectUuid={projectEntry.uuid}
        >
          {children}
        </Project.Remote.Mutations.ChangeProjectOwner.Initializer>
      </ProjectMember.Remote.Mutations.DeleteEntry.Initializer>
    </ProjectMember.Remote.Mutations.ChangeProjectMemberRole.Initializer>
  )
}

/**
 */
export const Invite = ({
  components: { Wrapper = Styled.InviteWrapper } = {},
}) => {
  const hasPermission = ProjectAuthorization.Remote.Queries.MyEntry.useHasPermissions()

  if (!hasPermission(Permissions.MembersInvite)) {
    return null
  }

  return (
    <Wrapper>
      <Menus.Invite />
    </Wrapper>
  )
}

/**
 */
export const Entries = ({
  children,
  components: {
    Wrapper = Styled.EntriesWrapper,
    Header = Styled.EntriesHeader,
  } = {},
}) => (
  <Wrapper>
    <Header>
      <Invite />
    </Header>
    <ProjectMember.Scope.Entries.Each>
      {children}
    </ProjectMember.Scope.Entries.Each>
  </Wrapper>
)

const EntrySelector = () => {
  return <input type="checkbox" />
}

const ForMemberOnly = ({ children }) => {
  const [entry] = ProjectMember.Scope.Entry.useEntry()

  if (entry.role === ProjectMemberRoles.Owner) {
    return null
  }

  return <>{children}</>
}

/**
 */
export const Entry = ({
  components: {
    Wrapper = Styled.EntryWrapper,
    FieldSelector = Styled.EntryFieldSelector,
    FieldMenu = Styled.EntryFieldMenu,
    FieldFill = Styled.EntryFieldFill,
    FieldFit = Styled.EntryFieldFit,
    FieldName = Styled.EntryFieldName,
  } = {},
}) => {
  return (
    <Wrapper>
      {/*　削除？ */}
      <ProjectAuthorization.ContentForRole
        role={ProjectAuthorizationRoles.Owner}
      >
        <FieldSelector>
          <ForMemberOnly>
            <EntrySelector />
          </ForMemberOnly>
        </FieldSelector>
      </ProjectAuthorization.ContentForRole>

      <FieldName>
        <BaseEntry.Fields.FullName />
      </FieldName>
      <FieldFill>
        <BaseEntry.Fields.Email />
      </FieldFill>
      <FieldFit>
        <BaseEntry.Role />
      </FieldFit>

      <ProjectAuthorization.ContentForRole
        role={ProjectAuthorizationRoles.Owner}
      >
        <FieldMenu>
          <ForMemberOnly>
            <BaseEntry.Menu />
          </ForMemberOnly>
        </FieldMenu>
      </ProjectAuthorization.ContentForRole>
    </Wrapper>
  )
}

/**
 */
export const Header = ({ components: { Title = Styled.Title } = {} }) => (
  <Title>
    <Literals.Title />
  </Title>
)

/**
 */
export const Footer = () => null
