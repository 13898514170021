import React from 'react'

import * as Modal from '@arch-log/webapp.atomics/components/Modal'
import * as Content from './Content'

/**
 */
export const useConfirmModal = () => {
  const { show, hide } = React.useContext(Modal.Context)

  const showConfirm = ({ message, ...props }) => {
    show(() => <Content.Default message={message} {...props} />)
  }

  return { showConfirm, hideConfirm: hide }
}
