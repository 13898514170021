import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Invite = translated('app.project')('MemberList.Invite')

/**
 */
export const Title = translated('app.project')('MemberList.Title')
