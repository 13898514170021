import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'

import * as Containers from './Containers'
import * as Styled from './Styled'
import * as Layout from './Layout'

/**
 */
export const Default = withDefaultRenderComponent(
  ({
    message,
    cancel = true,
    ok = true,
    onCancel = () => {},
    onOk = () => {},
    okProps,
    cancelProps,
    components: {
      ButtonContainer = Styled.ButtonContainer,
      Message = Styled.Message,
      Ok: okComponents,
      Cancel: cancelComponents,
      Layout: {
        Wrapper = Layout.Wrapper,
        Body = Layout.Body,
        Footer = Layout.Footer,
      } = {},
    } = {},
    ...props
  }) => {
    return (
      <Wrapper>
        <Body>
          <Message>{message}</Message>
        </Body>
        <Footer>
          <ButtonContainer>
            <Containers.Ok
              onOk={onOk}
              isVisible={ok}
              components={okComponents}
              {...okProps}
            />
            <Containers.Cancel
              onCancel={onCancel}
              isVisible={cancel}
              components={cancelComponents}
              {...cancelProps}
            />
          </ButtonContainer>
        </Footer>
      </Wrapper>
    )
  },
)
