import React from 'react'

import {
  translated,
  useTranslation,
} from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
const Fragment = ({ children }) => <>{children}</>

/**
 */
export const ChangeOwner = translated('app')('MemberList.ChangeOwner')

/**
 */
export const ChangeOwnerMessage = translated('app')(
  'MemberList.ChangeOwnerMessage',
)

/**
 */
export const RemoveMember = translated('app')('MemberList.RemoveMember')

/**
 */
export const RemoveMemberMessage = translated('app')(
  'MemberList.RemoveMemberMessage',
)

// FIXME: should be implement render on Containers.jsx
export const RoleName = ({ role, render: Renderer = Fragment, ...props }) => {
  const { t } = useTranslation('app.project')

  return <Renderer {...props}>{t(`MemberList.Roles.${role}`)}</Renderer>
}
