import React from 'react'

import { classNames } from '@arch-log/webapp.lib/CssHelper'
import * as styles from './styles.module.scss'

export const DefaultOptionRenderer = ({
  value,
  label,
  labelFormatter = (v) => v,
}) => <option value={value}>{label}</option>

/**
 */
export const RoleSelector = ({
  children,
  defaultValue,
  options = [],
  labelFormatter,
  renderOption: OptionRenderer = DefaultOptionRenderer,
  ...props
}) => {
  const items = options.map((opt) => (
    <OptionRenderer key={opt.value} labelFormatter={labelFormatter} {...opt} />
  ))

  return (
    <select
      defaultValue={defaultValue}
      {...props}
      className={styles.RoleSelector}
    >
      {items}
    </select>
  )
}

export const RoleTag = ({ children }) => (
  <div className={styles.RoleTag}>{children}</div>
)

/**
 */
export const MenuWrapper = React.forwardRef(({ children, ...props }, ref) => (
  <div ref={ref} className={styles.MenuWrapper} {...props}>
    {children}
  </div>
))

/**
 */
export const MenuToggle = ({ children, isToggled, ...props }) => (
  <div
    className={isToggled ? styles.MenuToggle_Toggled : styles.MenuToggle}
    {...props}
  >
    {children}
  </div>
)

/**
 */
export const MenuItems = ({ children, isShown = false }) => (
  <div
    className={classNames([
      styles.MenuItems,
      isShown ? styles.MenuItems_Visible : styles.MenuItems_Hidden,
    ])}
  >
    {children}
  </div>
)

/**
 */
export const MenuItem = ({ children }) => (
  <div className={styles.MenuItem}>{children}</div>
)

/**
 */
export const NameWrapper = ({ children }) => (
  <span className={styles.Name}>{children}</span>
)

/**
 */
export const MenuButton = ({ children, ...props }) => (
  <div {...props}>{children}</div>
)
