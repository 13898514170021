import React from 'react'

import * as ProjectMember from '@arch-log/webapp.modules/project.member.v2/components'

import * as Styled from './Styled'

/**
 */
export const FullName = ({
  components: { Wrapper = Styled.NameWrapper } = {},
}) => (
  <Wrapper>
    <ProjectMember.Scope.Entry.Properties.LastName />
    <ProjectMember.Scope.Entry.Properties.FirstName />
  </Wrapper>
)

/**
 */
export const Email = () => <ProjectMember.Scope.Entry.Properties.Email />
