// extracted by mini-css-extract-plugin
export var MenuItem = "styles-module--MenuItem--z5AVE";
export var MenuItems = "styles-module--MenuItems--ElwmD";
export var MenuItems_Hidden = "styles-module--MenuItems_Hidden--mR8b7";
export var MenuItems_Visible = "styles-module--MenuItems_Visible--YYX2v";
export var MenuToggle = "styles-module--MenuToggle--+4kQr";
export var MenuToggle_Toggled = "styles-module--MenuToggle_Toggled--47tre";
export var MenuWrapper = "styles-module--MenuWrapper--ThRQg";
export var Name = "styles-module--Name--Oa0PS";
export var RoleSelector = "styles-module--RoleSelector--Z9UnQ";
export var RoleTag = "styles-module--RoleTag--5v5M8";