import React from 'react'

import * as Icons from '@arch-log/webapp.shared/Icons'

import * as Literals from './Literals'
import * as ProjectLinks from '@arch-log/webapp.shared/Project.v2/Links'

import * as styles from './styles.module.scss'

/**
 * FIXME
 */
export const Invite = ({}) => (
  <ProjectLinks.ToInvite className={styles.ToInvite}>
    <Icons.CreateNew className={styles.ToInvite_Icon} />
    <Literals.Invite />
  </ProjectLinks.ToInvite>
)
